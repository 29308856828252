import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/commercial-services.sass"

const Commercial = () => (
  <div className="commercial-component">
    <SEO title="commercial services" />
    <div className="commercial-header">
      <h1>COMMERCIAL DRAIN AND SEWER CLEANING SERVICES</h1>
    </div>
    <Image filename="commercial.JPEG"/>
    <div className="commecial-services-statements blue">    
      <p>
        Everyone has a duty to protect the integrity and hygiene of their workplace environment. 
        From routine maintenance to storm drain cleaning services, Tony’s Drain and Sewer Commercial Cleaning service is here to help. 
        Through dedicated, professional, and highly-trained technicians, we’ll help eliminate the hassle and get the job done.
      </p>
    </div>
    <div className="commecial-services-statements commecial-services-statements-left">
      <h3>COMMERCIAL DRAIN AND SEWER REPAIR & CLEANING</h3>
      <p>
      Commercial, industrial and municipal drains and sewers need servicing also. 
      Foreign objects and other belongings can cause blockages that require professional help. 
      This includes but is not limited to commercial bathrooms, kitchens, floors, and anywhere else.
      </p>
    </div>
    <div className="commecial-services-statements commecial-services-statements-right">
      <h3>MODERN EQUIPMENT</h3>
      <p>
        High-caliber equipment means high-caliber results. 
        Clogged sewer lines, stuck outdoor drains, and blocked bathrooms can really throw a wrench in your operations. 
        With the help of state of the art equipment, we’ll make quick work of the toughest jobs. 
        It’s a combination of training and equipment that make us one of the most professional commercial drain and commercial sewer teams around. 
      </p>
    </div>
    
    <div className="commecial-services-statements commecial-services-statements-left">
      <h3>TRAINED STAFF</h3>
      <p>
        When that time comes, we’ll be ready to help resolve your emergency.
        Give us a call and our team of professional commercial sewer and commercial drain cleaners will get out to you as soon as possible.
        Whether we’re helping prevent water damage or cleaning out your clogged sewer, we’ll do it with a passion.
      </p>
    </div>

    <div className="commecial-services-statements commecial-services-statements-right">
      <h3>
        It All Comes From Decades Of Experience
      </h3>
    </div>

  </div>
)

export default Commercial
