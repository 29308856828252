import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import "../../assets/sewer-cleaning.sass"
import Carousel from 'react-bootstrap/Carousel'

const SewerCleaning = () => (
  <div className="sewer-cleaning-component">
    
    <SEO title="Sewer Cleaning" />
    
    <div className="service-header">
      <h1>Sewer Cleaning</h1>
      <h3>Power Rodding</h3>
    </div>

    <div className="sewer-cleaning-images">
      <Carousel>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="tonys-rodder.jpg"/>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="root-clog.png"/>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="power-rodding-glove.JPG"/>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>

    <div className="sewer-cleaning-statements">
      <p>
        A common cause of a sewer backup is a blockage in the pipe that runs between your home and the city’s main sewer line. 
        Blockages in sewers can be caused by<span className="bold-text"> root intrusion, non-dissolvable items like baby wipes or female products.</span>
        Other causes of backups can be <span className="bold-text"> grease, or cast iron build up in the line.</span> 
        The sewer pipe that runs from your home to the property line is owned and maintained by the homeowner.
      </p>

    </div>

    <div className="sewer-cleaning-statements sewer-cleaning-statements-blue">
      <p>
        Many people are in the habit of pouring grease and oil down the drain, if you are one of them please, stop! Fatty liquids will cool and coagulate in your pipes and eventually become too dense to be pushed through by water. Hardened grease and oil can constrict your pipes, making it difficult for waste particles to move through.
      </p>
    </div>

    <div className="video-container desktop-image-video">
      <video playsInline autoPlay loop muted>
        <source src={"https://firebasestorage.googleapis.com/v0/b/tonysdrain-ef299.appspot.com/o/camera-inpection-service.mp4?alt=media&token=46a79159-9412-4230-80fd-f5c16d30cb02"} type="video/mp4" />
      </video>
    </div>
    <div className="sewer-cleaning-statements desktop-statements">
      <p>
      Drainage and flooding problems in and around your home can be costly and damaging. 
      There are many products for sale in grocery and home improvement for drain cleaning and clogs of all types. 
      <span className="bold-text"> We strongly encourage clients to avoid using chemical drain cleaners</span>, 
      especially if your home’s plumbing is old. 
      Which can also cause more damages then benefits to the pipe specially on an old pipes.
      </p>
    </div>

  </div>
)

export default SewerCleaning
