import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import "../../assets/hydro.sass"
import Carousel from 'react-bootstrap/Carousel'

const statement =
  <div className="company-equipment-statement">
    <h3>
      We use state of the art equipment
    </h3>
    <p>
      The pressure of the water goes from 3000 PSI up to 5000 SPI. The highly trained technician controls the pressure, the Hydro Jetting tool uses a rotating nozzle with multiple jets. Our machine can heat up the water up to 200 degrees Fahrenheit in order to dissolve all the grease accumulation in the sewer line.
    </p>
  </div>

const Hydro = () => (
  <div className="hydro-component">
    <SEO title="hydro jetting" />

    <div className="service-header">
      <h1>HYDRO JETTING</h1>
    </div>
    
    <div className="statements-blue">
      <p>
        Hydro jetting is the process of using a high-pressure water to scour the interior surfaces of the plumbing pipes, removing scale, grease, roots and other debri that builds up inside the pipe over time. Different from rodding, where a technician uses a tool to power a hole through the buildup, hydro jetting achieves a comprehensive cleaning of the pipes.
      </p>
    </div>

    <div className="hydro-jetting-header">
      <div className="video-container desktop-image-video">
        <video playsInline autoPlay loop muted>
          <source src={"https://firebasestorage.googleapis.com/v0/b/tonysdrain-ef299.appspot.com/o/homepage-hydro-jet.mp4?alt=media&token=039dfd63-09c1-4737-9fcf-7f51302595c2"} type="video/mp4" />
        </video>
      </div>
      <p className="hydro-jet-explained desktop-statements">
        Hydro jetting is used when the line has accumulation of grease, human feces, root instruction, paper towels and a regular power rodding does not work.
      </p>
    </div>

  
    <div className="company-equipment">
    
    <div className="company-equipment-statement desktop-statement-view">
      <h3>
        We use state of the art equipment
      </h3>
      <p>
        The pressure of the water goes from 3000 PSI up to 5000 SPI. The highly trained technician controls the pressure, the Hydro Jetting tool uses a rotating nozzle with multiple jets. Our machine can heat up the water up to 200 degrees Fahrenheit in order to dissolve all the grease accumulation in the sewer line.
      </p>
    </div>
    
      <div className="hydro-jetting-illustraion">
        <Image filename="root-hydro-illustration.jpg"/>
      </div>

      <div className="company-equipment-statement mobile-statement-view">
        <h3>
          We use state of the art equipment
        </h3>
        <p>
          The pressure of the water goes from 3000 PSI up to 5000 SPI. The highly trained technician controls the pressure, the Hydro Jetting tool uses a rotating nozzle with multiple jets. Our machine can heat up the water up to 200 degrees Fahrenheit in order to dissolve all the grease accumulation in the sewer line.
        </p>
      </div>

    </div>
    
    <div className="hydro-jetting-images">
      <Carousel>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="hydro-jetter-man-hole.JPG"/>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <div className="scoping-images">
            <Image filename="hydro-jet-back.jpg"/>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <div className="scoping-images">
            <Image filename="worker-hydro.jpg"/>
          </div>
        </Carousel.Item>

      </Carousel>
    </div>

  </div>
)

export default Hydro
